import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import ExperienceAccordion from "../../containers/experienceAccordion/ExperienceAccordion.js";
import "./Experience.css";
import { Fade } from "react-reveal";

const experience = {
  title: "Experience",
  subtitle: "",
  description: "",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Freelance IT/BI Consultant",
          company: "Ascot Comm.V",
          company_url: "https://www.ascot.consulting/",
          logo_path: "ascot.png",
          duration: "Jun 2017 - Present",
          location: "Belgium",
          description: `Freelance IT services consultant, with expertise in BI solutions (mainly MS BI stack) and working mainly in financial sector.
                <ul>
                    <li>
                Creation of Regulatory reporting app (fully automated EBA DPM import, submission, validations, integration with source data...)) 
                    </li>
                    <li>
                Data Analist: Basel IV reporting with Power BI
                    </li>
                    <li>
                Data Engineer: Moving away from traditional ETL to cloud ETL/ELT platforms (python, Mage AI, Azure Databricks,...)
                    </li>
                </ul>`,
          color: "#181717",
        },
        {
          title: "Senior BI Consultant",
          company: "b-fine",
          company_url: "https://www.regnology.net/",
          logo_path: "bfine.png",
          logo2_path: "regnology.png",
          duration: "Jun 2017 - Present",
          location: "On-site",
          description: `On-site technical consultant for b-fine customers. 
                   Implementation of new features with focus on reporting, both internal/management (BI) reporting and
                             regulatory reporting (filing to national,European regulators)`,
          color: "#181717",
        },
        {
          title: "Senior Report Developer/Analyst (Microsoft BI Stack)",
          company: "Wolters Kluwer Fincancial Services (Finarch)",
          company_url:
            "https://www.wolterskluwer.com/nl-be/solutions/onesumx-for-finance-risk-and-regulatory-reporting",
          logo_path: "wkfs.png",
          logo2_path: "finarch.jpg",
          duration: "Nov 2011 - Jun 2017",
          location: "Ghent (Belgium)",
          description: `<ul>
                                <li>Development of reports in Microsoft BI stack: Excel (PTS), PowerPivot, SSRS, SSAS (OLAP).</li>
                                <li>Gathering requirements (granularity, presentation of data, parameters etc).</li>
                                <li>Creation of fully automated OLAP platform: creating cubes (.Net/AMO) based on simple wizard (ASP.NET/MVC), loading OLAP warehouse (ETL to load dimensions, fact tables), optimized processing (partitioning), applying translations and automated actions (drill through to reporting services).</li>
                                <li>Analysing integration of company software with Power Pivot, Power View and tabular cubes.</li>
                                <li>Optimization /tuning of Risk calculators (Basel mitigation process)</li>
                                <li>Performance tuning</li>
                                <li>Analysing new (complex) features: comparing implementation options, creating proof of concept ...</li>
                             </ul>`,
          color: "#181717",
        },
        {
          title: "Senior Developer within Risk and Capital Management Dept",
          company: "Wolters Kluwer Fincancial Services (Finarch)",
          company_url:
            "https://www.wolterskluwer.com/nl-be/solutions/onesumx-for-finance-risk-and-regulatory-reporting",
          logo_path: "wkfs.png",
          logo2_path: "finarch.jpg",
          duration: "Oct 2009 - Oct 2011",
          location: "Ghent (Belgium)",
          description: `<ul><li>RCM development; heavy focus on Liquidity Risk</li>
                                 <li>Creation of Olap Cubes including drillthrough reporting functionalities (details in grid, reporting services etc).</li>
                                 <li>Creation of framework for performance testing.</li>
                              </ul>`,
          color: "#181717",
        },
        {
          title: "Presales",
          company: "Wolters Kluwer Fincancial Services (Finarch)",
          company_url:
            "https://www.wolterskluwer.com/nl-be/solutions/onesumx-for-finance-risk-and-regulatory-reporting",
          logo_path: "wkfs.png",
          logo2_path: "finarch.jpg",
          duration: "Feb 2008 - Oct 2009",
          location: "Merelbeke (Belgium)",
          description: `<ul><li>Preparing demos + technical questionnaires (Proof of Concept for Tier-1 banks)</li>
                            <li>Enhancement of standard product</li>
                            <li>Presentating new functionalities...</li>
                             </ul>`,
          color: "#181717",
        },
        {
          title: "Senior technical consultant",
          company: "Wolters Kluwer Fincancial Services (Finarch)",
          company_url:
            "https://www.wolterskluwer.com/nl-be/solutions/onesumx-for-finance-risk-and-regulatory-reporting",
          logo_path: "wkfs.png",
          logo2_path: "finarch.jpg",
          duration: "Apr 2004 - Feb 2008",
          location: "Merelbeke (Belgium)",
          description: `First 6 months I was VB/SQL Server developer.
Then I became Technical Business Consultant:
Did projects for major banks situated in Brussels, Amsterdam, Luxembourg, Frankfurt, London, The Hague, Sydney, Singapore.
Tasks consisted of building DTS packages, SQL Agent Jobs, stored procedures,... in MS platform (SQL Server, C# , ASP.NET) to create a platform for Financial,Management... reporting.`,
          color: "#181717",
        },
        {
          title: "Manager of Easyplan",
          company: "Easypay nv",
          company_url: "https://www.easypay-group.com/",
          logo_path: "easypay.png",
          duration: "Jan 2004 - Apr 2004",
          location: "Meulebeke (Belgium)",
          description: `Responsible for Easyplan - the HRM branch of Easyplan.
Managing budgets for the projects / forecasting / coding in COBOL / giving demo's to clients`,
          color: "#181717",
        },
        {
          title: "Software Designer",
          company: "Trisoft NV",
          logo_path: "trisoft.jpg",
          duration: "Apr 2001 - Sept 2003",
          location: "Antwerp (Belgium)",
          description: `<ul>
                            <li> Reverse engineering tasks (using existing code as input for a design document with diagrams, according the UML methodology). </li>
<li> Extension of a project on web application on a Windows 2000 platform using COM+ and some .Net framework facilities like web services’, enhancing existing modules and also making new ones, including the design as well as the programming part.</li>
<li> The design consisted of GUI description (user functionalities) and diagrams (clarifying the internal working: activity diagrams & state transition diagrams). The coding was done in html, asp and Visual Basic. The asp’s did the work at the server side (by calling functions inside a VB dll). We also used XSL to convert XML documents (stored in a database) into HTML documents in order to publish them on the web. The environment could be described as a ‘web application on a Windows 2000 platform using COM+ and some .Net framework facilities like web services’.</li>
                            </ul>`,
          color: "#181717",
        },
        {
          title: "Software Designer",
          company: "TelaAtlas",
          logo_path: "teleatlas.jpg",
          duration: "May 2000 - Apr 2001",
          location: "Ghent (Belgium)",
          description: `<ul><li> Analysis and implementation in Delphi 5</li>
                                <li> Responsible for a number of modules of an application capable of detecting errors in a geographical database(validation of data correctness).
                                </li></ul>`,
          color: "#181717",
        },
        {
          title: "Software Engineer",
          company: "Dekimo NV",
          company_url: "https://www.dekimo.com/",
          logo_path: "dekimo.jpg",
          duration: "Dec 1999 - May 2000",
          location: "Gentbrugge (Belgium)",
          description: "Programming in Visual C++ 5.0 and use of SQL Server 7",
          color: "#181717",
        },
      ],
    },
  ],
};

class Experience extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="page-content">
        <Header theme={theme} />
        <div className="basic-experience">
          <Fade bottom duration={2000} distance="40px">
            <div className="experience-heading-div">
              <div className="experience-heading-text-div">
                <h1 className="title">{experience.title}</h1>
                <h3
                  className="experience-heading-sub-text"
                  style={{ color: theme.text }}
                >
                  {experience["subtitle"]}
                </h3>
                <p
                  className="experience-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {experience["description"]}
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <ExperienceAccordion sections={experience["sections"]} theme={theme} />
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Experience;
