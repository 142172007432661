import React, { useState, useRef } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";

const Contact = (props) => {
  const form = useRef();
  const theme = props.theme;

  const [topic, setTopic] = useState("");
  const [from_name, setFromName] = useState("");
  const [user_email, setUserEmail] = useState("");
  const [message, setMessage] = useState("");

  const validateForm = () => {
    if (from_name.length === 0) {
      toast.error("Please give your name.");
      return false;
    }

    if (!validateEmail(user_email)) {
      toast.error("Please provide a correct email address.");
      return false;
    }

    if (topic === "") {
      toast.error("Please choose a topic.");
      return false;
    }

    if (message.length === 0) {
      toast.error("Please type something in the message box.");
      return false;
    }

    return true;
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit �Send�

    var validData = validateForm();

    if (validData) {
      emailjs
        .sendForm("Ascot", "contact", form.current, "2D3pStWSXs1otPr6t")
        .then(
          (result) => {
            toast.success("Your message was sent successfully.");
          },
          (error) => {
            toast.error("There was an isue sending your message!");
          }
        );

      setTopic("");
      setFromName("");
      setUserEmail("");
      setMessage("");
    }
  };

  const handleTopicSelect = (e) => {
    // console.log(e.target.value);
    setTopic(e.target.value);
  };

  const handleFromNameChange = (event) => {
    setFromName(event.target.value);
  };

  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <div className="contact-main">
      <Header theme={theme} />
      <div className="page-content">
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },
          }}
        />
        <Fade bottom duration={1000} distance="40px">
          <div className="row topline align-items-center">
            <div className="col">
              <div className="contact-heading-text-div">
                <h1 className="title">Contact Me</h1>
                <div>
                  <form ref={form} onSubmit={sendEmail}>
                    <div className="mb-3">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="from_name"
                        value={from_name}
                        onChange={handleFromNameChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="user_email"
                        value={user_email}
                        onChange={handleUserEmailChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Subject</label>
                      <input
                        type="hidden"
                        className="form-control"
                        name="topic"
                        value={topic}
                      />
                      <select
                        className="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        defaultValue={"Choose a subject..."}
                        onChange={handleTopicSelect}
                      >
                        <option value="I have a job opportunity/project proposal">
                          I have a job opportunity/project proposal
                        </option>
                        <option value="I have a question on one of your projects">
                          I have a question on one of your projects
                        </option>
                        <option value="I have another question">
                          I have another question
                        </option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Message</label>
                      <textarea
                        name="message"
                        className="form-control"
                        value={message}
                        onChange={handleMessageChange}
                      />
                    </div>
                    <input
                      type="submit"
                      className={"btn sendbtn"}
                      value="Send"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="col" style={{ textAlign: "center" }}>
              <div className="contact-heading-img-div">
                <img
                  src={require(`../../assests/images/bartdelouvroy.jpg`)}
                  alt=""
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <span
                  className="iconify"
                  data-icon="material-symbols:mail-outline"
                  data-inline="false"
                ></span>
                <span>&nbsp; bart@ascot.consulting</span>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      <Footer theme={props.theme} onToggle={props.onToggle} />
      <TopButton theme={props.theme} />
    </div>
  );
};

export default Contact;
