import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";

export default function Footer(props) {
  return (
    <footer className="footer">
      <Fade>
        <div
          className="text-center p-4 "
          style={{
            color: props.theme.footerColor,
            backgroundColor: props.theme.menuBackground,
          }}
        >
          <div>
            <a
              style={{ color: props.theme.footerColor, textDecoration: "none" }}
              href="https://www.ascot.consulting"
            >
              {" "}
              &nbsp; Ascot.Consulting
            </a>
          </div>
          <div>
            <iconify-icon icon="ic:sharp-copyright"></iconify-icon>{" "}
            {new Date().getFullYear()}
          </div>
        </div>
      </Fade>
    </footer>
  );
}
