/* Change this file to get your personal Porfolio */

// Website related settings
const logo = {
  mobile: "/assets/images/logo.png",
  desktop: "logo.png",
  desktop_height: "72px",
  alt: "Ascot - Consulting",
};

//SEO Related settings
const seo = {
  title: "Ascot Consulting",
  description:
    "ASCOT GCV is een IT consulting bedrijf actief in business intelligence, technische implementaties in financiele software en functionele websites.",
  og: {
    title: "Ascot Consulting",
    type: "website",
    url: "http://ascot.consulting/",
  },
};

//Home Page
const greeting = {
  title: "Ascot Consulting",
  logo_name: "Ascot Consulting",
  nickname: "BDL",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  //{
  //  name: "Github",
  //  link: "https://github.com/ashutosh1919",
  //  fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
  //  backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  //},
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/bartdelouvroy/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
];

const skills = {
  data: [
    {
      title: "Banking software implementations",
      fileName: "banking_impl.png",
      skills: [
        `<ul>
                <li>Finance:</li>
                <ul>
                    <li>Posting process for BE GAAP, IFRS</li>
                    <li>Consolidation process</li>
                    <li>Reporting on balances (solo/conso)</li>
                </ul>
                <li>Risk Management:
                <ul>         
                    <li>Basel I/II/III</li>
                    <li>ALM:LCR/NSFR/AMM</li>                    
                </ul>
                <li>Compliance:</li>
                <ul>
                    <li>EBA reporting: FINREP/COREP </li>
                    <li>XBRL/XML generation for various national regulators</li>
                </ul>
                <li>Treasury: management reporting (from pixel perfect to generic BI reports)</li>
            </ul>`,
      ],
      softwareSkills: [],
    },
    {
      title: "Reporting",
      fileName: "reporting.svg",
      skills: [
        `<ul>
                <li>Understanding reporting needs and deciding on which type of report need to be made</li>
                <li>Creation of underlying data mart (if needed)</li>
                <li>Integration in existing application: linking in UI, foreseeing parameter screen</li>
                <li>Applying specific layout in such way that data is turned into information</li>
                <li>Optimization of user experience: filters with regular expressions, from aggregated to detailed reports </li>
            </ul>`,
      ],
      softwareSkills: [
        {
          skillName: "MS Excel",
          fileName: "excel.svg",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "MS SQL Server Reporting Services",
          fileName: "ms_ssrs.png",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "MS SQL Server  Analysis Services",
          fileName: "ms_ssas.png",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "MS Power Pivot",
          fileName: "ms_powerpivot.jpg",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Power BI",
          fileName: "New_Power_BI_Logo.svg",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Data modeling",
      fileName: "data_models.svg",
      skills: ` <ul>
                <li>Creation:</li>
                <ul>
                    <li>logical model</li>
                    <li>physical model (SQL Server, Postgres)</li>
                </ul>
                <li>Assessment of correct model given the specific needs:
                <ul>         
                    <li> OLTP(SQL, NoSQL)</li>
                    <li>data marts(star schema)</li>
                    <li>data warehouses(data vault)</li>
                </ul>
                <li>Advanced features:</li>
                <ul>
                    <li>ETL vs ELT: extracting, mapping, enrichment,loading </li>
                    <li>optimization through pre-aggregated data</li>
                    <li>scalability and flexibility</li>
                    <ul>
                        <li>dynamically adding hierarchical data without the need to re-develop the model</li>
                        <li>making historical changes in hierarchical data (adding levels, moving nodes) without breaking the model</li>
                    </ul>
                    <li>correct handling of time dimension: eg financial data for time period aggregated </li>
                </ul>
                <li>A to Z implemention of BI solutions: MS SSAS(MDX,Tabular Olap cubes), PowePivot, PowerBI</li>
            </ul>`,
      softwareSkills: [
        {
          skillName: "MS SQL Server",
          fileName: "microsoft-sql-server-logo-svgrepo-com.svg",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "logos:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Power BI",
          fileName: "New_Power_BI_Logo.svg",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Database tuning",
      skills: `<ul>
                    <li>Optimization of indexes</li>
                    <li>Analyzing query plans</li>
                    <li>Rewrite queries</li>
                    <li>Using db features (table partitioning, compression,…)</li>
                </ul>`,
      softwareSkills: [
        {
          skillName: "MS SQL Server",
          fileName: "microsoft-sql-server-logo-svgrepo-com.svg",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "logos:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      skills: [
        `<ul>
                <li>Building responsive website front end with ReactJS and Bootstrap/Material Design</li>
                <li>Creation of application backend: Rest / GraphQL API (.Net Core WebAPI)</li>
                <li>Storage of dynamic UI elements in data layer: API passing through data and dynamically rendered in UI</li>
                <li>Creation of static, dynamic or hybrid websites (API driven but pre-compiled and optimized with GatsbyJS)</li>
            </ul>`,
      ],
      softwareSkills: [
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "API Rest",
          fileName: "api_rest.png",
          width: "60px",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "API Graphql",
          fileName: "api_graphql.png",
          width: "60px",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "DevOps",
      fileName: "devops.svg",
      skills: [
        ` <ul>
            <li>Experience working with deployments on MS Azure cloud platforms</li>
            <li>Linking domain to website, install certificates (https) ...</li>
            <li>Continuous integration: merge, build, deploy (Jenkins, TFS, github actions)</li>
            <li>Basic knowledge in virtualization (docker)
          </ul>`,
      ],
      softwareSkills: [
        {
          skillName: "Azure",
          fontAwesomeClassname: "vscode-icons:file-type-azure",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "logos:jenkins",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Github",
          fontAwesomeClassname: "mdi:github",
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Vrije Universiteit Brussel",
      subtitle: "Master in Applied Informatics",
      logo_path: "vub.jpg",
      alt_name: "VUB",
      duration: "1995 - 1999",
      descriptions: [
        "⚡ Focus on computer language concept (interprete vs compile, functional vs object oriented) rather than learning specific software languages",
        "⚡ Writing algoritms for all kinds of scenarios",
      ],
      website_link: "https://www.vub.be/",
    },
    {
      title: "Koninklijk Atheneum Ronse",
      subtitle: "ASO",
      logo_path: "ka_ronse.jpg",
      alt_name: "KA Ronse",
      duration: "1988 - 1994",
      descriptions: ["Economie Wiskunde"],
      website_link: "https://davincicampus.be/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Prince 2",
      subtitle: "",
      logo_path: "prince2.jpg",
      color_code: "#8C151599",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Associate AI Engineer",
          company: "Legato Health Technology",
          company_url: "https://legatohealthtech.com/",
          logo_path: "legato_logo.png",
          duration: "June 2020 - PRESENT",
          location: "Hyderabad, Telangana",
          description:
            "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
          color: "#0879bf",
        },
        {
          title: "Android and ML Developer",
          company: "Muffito Incorporation",
          company_url: "https://www.linkedin.com/company/muffito-inc/about/",
          logo_path: "muffito_logo.png",
          duration: "May 2018 - Oct 2018",
          location: "Pune, Maharashtra",
          description:
            "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
          color: "#9b1578",
        },
        {
          title: "Android Developer",
          company: "FreeCopy Pvt. Ltd.",
          company_url: "https://www.linkedin.com/company/freecopy/about/",
          logo_path: "freecopy_logo.png",
          duration: "Nov 2017 - Dec 2017",
          location: "Ahmedabad, Gujarat",
          description:
            "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Data Science Research Intern",
          company: "Delhivery Pvt. Ltd.",
          company_url: "https://www.delhivery.com/",
          logo_path: "delhivery_logo.png",
          duration: "May 2019 - Sept 2019",
          location: "Gurgaon, Haryana",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
        {
          title: "Data Science Intern",
          company: "Intel Indexer LLC",
          company_url:
            "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "intel_logo.jpg",
          duration: "Nov 2018 - Dec 2018",
          location: "Work From Home",
          description:
            "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://www.mozilla.org/",
          logo_path: "mozilla_logo.png",
          duration: "Oct 2019 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
          color: "#000000",
        },
        {
          title: "Developer Students Club Member",
          company: "DSC IIITDM Kurnool",
          company_url:
            "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
          logo_path: "dsc_logo.png",
          duration: "Jan 2018 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
          color: "#0C9D58",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects include layered (data, business logic , front end) services deployed/hosted on-premise or in cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const projects = {
  data: [
    {
      name: "RegNxt",
      createdAt: "2023 (ongoing)",
      url: "https://www.regnxt.eu",
      description:
        "Intuitive regulatory and management reporting tool. Covering data ingestion, report editting, validation, submission, variance analysis, reconciliations.",
      languages: [
        {
          name: "ReactJs",
          iconifyClass: "simple-icons:react",
        },
        {
          name: "C#",
          iconifyClass: "logos-c-sharp",
        },
        {
          name: "MS SQL Server",
          iconifyClass: "simple-icons:microsoftsqlserver",
        },
        {
          name: "Python",
          iconifyClass: "vscode-icons:file-type-python",
        },
        {
          name: "MS Power BI",
          iconifyClass: "logos:microsoft-power-bi",
        },
        {
          name: "Azure Databricks",
          logo_path: "databricks.svg",
        },
      ],
    },
    {
      name: "Horecavitrine",
      createdAt: "2022-09-19T14:24:55Z",
      url: "",
      description:
        "SEO optimized website for publishing horeca companies (with or without real estate). Includes self-registry for anyone and fully functional admin part.",
      languages: [
        {
          name: "ReactJs",
          iconifyClass: "simple-icons:react",
        },
        {
          name: "C#",
          iconifyClass: "logos-c-sharp",
        },
        {
          name: "MS SQL Server",
          iconifyClass: "simple-icons:microsoftsqlserver",
        },
      ],
    },
    {
      name: "RealEstateDocs",
      createdAt: "2021-05-10T14:24:55Z",
      url: "",
      description:
        "Website for guiding which offical documents one needs before selling a real estate object. Guides through the regulations and stores the documents safely in the cloud (so it can be consulted anytime).",
      languages: [
        {
          name: "ReactJs",
          iconifyClass: "simple-icons:react",
        },
        {
          name: "C#",
          iconifyClass: "logos-c-sharp",
        },
        {
          name: "MS SQL Server",
          iconifyClass: "simple-icons:microsoftsqlserver",
        },
      ],
    },
    {
      name: "Regulatory Reporting Solution for EBA(DPM)",
      createdAt: "2019-03-03T05:00:56Z",
      description:
        "Visualization and storage of EBA (European Banking Authority) reports, validation of the data, generation of XBRL by leveraging on DPM database",
      languages: [
        {
          name: "ReactJs",
          iconifyClass: "simple-icons:react",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "C#",
          iconifyClass: "logos-c-sharp",
        },
        {
          name: "MS SQL Server",
          iconifyClass: "simple-icons:microsoftsqlserver",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos:postgresql",
        },
      ],
    },
    {
      name: "ReFruit",
      createdAt: "2016-03-06T16:26:54Z",
      description:
        "Website for selling deformed but perfectly healthy fruit and vegetables, including dashboard for clear overview on orders.",
      languages: [
        {
          name: "HTML",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS",
          iconifyClass: "logos-css-3",
        },
        {
          name: "AngularJS",
          iconifyClass: "simple-icons:angularjs",
        },
        {
          name: "C#",
          iconifyClass: "logos-c-sharp",
        },
        {
          name: "MS SQL Server",
          iconifyClass: "simple-icons:microsoftsqlserver",
        },
      ],
    },
    {
      name: "Het Snelste Ei",
      createdAt: "2017-03-03T11:53:55Z",
      description:
        "Game where teams compete against each other in a kind of geocaching race",
      languages: [
        {
          name: "HTML",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS",
          iconifyClass: "logos-css-3",
        },
        {
          name: "AngularJS",
          iconifyClass: "simple-icons:angularjs",
        },
        {
          name: "C#",
          iconifyClass: "logos-c-sharp",
        },
        {
          name: "MS SQL Server",
          iconifyClass: "simple-icons:microsoftsqlserver",
        },
      ],
    },
  ],
};

export {
  logo,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  projects,
};
