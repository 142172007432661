import React from "react";
import "./ProjectLanguages.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function ProjectLanguages(props) {
  function render_logo(logo) {
    if (Object.hasOwn(logo, "iconifyClass")) {
      return (
        <span
          className="iconify"
          data-icon={logo.iconifyClass}
          data-inline="false"
        ></span>
      );
    } else if (Object.hasOwn(logo, "logo_path")) {
      console.log("logo with svg:", logo);
      return (
        <img
          className="language_logo"
          src={require(`../../assests/images/${logo.logo_path}`)}
          alt={logo.name}
        />
      );
    }
  }

  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons-languages">
          {props.logos.map((logo) => {
            return (
              <OverlayTrigger
                key={logo.name}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    <strong>{logo.name}</strong>
                  </Tooltip>
                }
              >
                <li
                  className="software-skill-inline-languages"
                  name={logo.skillName}
                >
                  {render_logo(logo)}
                </li>
              </OverlayTrigger>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
