import React from "react";
import "./SoftwareSkill.css";

class SoftwareSkillImg extends React.Component {
  render() {
    if (this.props.logo.fontAwesomeClassname) {
      return (
        <span
          className="iconify"
          data-icon={this.props.logo.fontAwesomeClassname}
          style={this.props.logo.style}
          data-inline="false"
        ></span>
      );
    } else {
      return (
        <img
          alt={this.props.logo.skillName}
          width={this.props.logo.width ? this.props.logo.width : ""}
          height={this.props.logo.height ? this.props.logo.height : "48px"}
          src={require(`../../assests/images/${this.props.logo.fileName}`)}
        ></img>
      );
    }
  }
}

export default SoftwareSkillImg;
