import React, { Component } from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import { NavLink, Link } from "react-router-dom";
import { logo } from "../../portfolio.js";
import SeoHeader from "../seoHeader/SeoHeader";
import ReactGA from "react-ga4";

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};

class Header extends Component {
  render() {
    const theme = this.props.theme;
    ReactGA.initialize("G-VZSRPSM4SK");
    ReactGA.send("pageview");

    return (
      <Fade top duration={1000} distance="20px">
        <SeoHeader />
        <header className="header">
          <div className="logo">
            <Link to="/">
              <img
                height={logo.desktop_height}
                alt={logo.alt}
                src={require(`../../assests/images/Logo.png`)}
              />
            </Link>
          </div>

          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <ul
            className="menu"
            style={{ backgroundColor: theme.menuBackground }}
          >
            <li>
              <NavLink
                to="/Who"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.menuItem }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Who
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/What"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.menuItem }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                What
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/How"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.menuItem }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                How
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/education"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.menuItem }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Education
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/experience"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.menuItem }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Experience
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/projects"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.menuItem }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.menuItem }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </header>
      </Fade>
    );
  }
}
export default Header;
