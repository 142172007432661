import React from "react";
import "./Bestpractices.css";
import { Fade } from "react-reveal";

export default function Bestpractices(props) {
  const theme = props.theme;
  return (
    <div className="page-content" id="skills">
      <div className="bestpractices-header-div">
        <Fade bottom duration={2000} distance="10px">
          <h1 className="title">How I tackle things</h1>
        </Fade>
      </div>

      <div className="bestpractices-main-div" theme={theme}>
        <div> Always striving following best practices:</div>
      </div>
      <div>
        <ul>
          <li>
            Adopt the (micro) services architecture: each component is focussing
            on a specific functionality
          </li>
          <li>
            Apply multi-tier: data(base) -> Business logic -> User interface
          </li>
          <li>
            UI talks to business logic through a secured API (REST / grahpql)
          </li>
          <li>
            Minimize hard coded sections in software by using
            <ul>
              <li>
                configuration (eg references to servers, network address,...)
              </li>
              <li>mapping tables in the data layer</li>
            </ul>
            This meta data driven approach reduces complexity and maintanance
            cost
          </li>
          <li>Create intuitive UI components that reflect the workflow</li>
        </ul>
      </div>
    </div>
  );
}
