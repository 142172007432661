import React, { Component } from "react";
import "./ExperienceCard.css";

class ExperienceCard extends Component {
  render() {
    const experience = this.props.experience;
    const theme = this.props.theme;
    return (
      <div
        className="experience-card"
        style={{ border: `1px solid ${experience["color"]}` }}
      >
        <div className="experience-card-logo-div">
          <img
            className="experience-card-logo"
            src={require(`../../assests/images/${experience["logo_path"]}`)}
            alt=""
          />
          {experience.hasOwnProperty("logo2_path") ? (
            <img
              className="experience-card-logo"
              src={require(`../../assests/images/${experience["logo2_path"]}`)}
              alt=""
            />
          ) : (
            <></>
          )}
        </div>
        <div className="experience-card-body-div">
          <div className="experience-card-header-div">
            <div className="experience-card-heading-left">
              <h3
                className="experience-card-title"
                style={{ color: theme.text }}
              >
                {experience["title"]}
              </h3>
              <p className="experience-card-company">
                <span
                  className="d-inline-block"
                  data-bs-toggle="tooltip"
                  title="Visit the company website"
                >
                  {experience.hasOwnProperty("company_url") ? (
                    <a
                      href={experience["company_url"]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {experience["company"]}
                    </a>
                  ) : (
                    <span> {experience["company"]}</span>
                  )}
                </span>
              </p>
            </div>
            <div className="experience-card-heading-right">
              <p
                className="experience-card-duration"
                style={{ color: theme.text }}
              >
                {experience["duration"]}
              </p>
              <p
                className="experience-card-location"
                style={{ color: theme.text }}
              >
                {experience["location"]}
              </p>
            </div>
          </div>
          <div
            className="experience-card-description"
            style={{ color: theme.text }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: experience["description"] }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExperienceCard;
