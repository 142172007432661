import React, { Component } from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";

function GetSkillSvg(props) {
  if (props.fileName) {
    return (
      <img
        alt={props.alt}
        width="250px"
        height="250px"
        src={require(`../../assests/images/${props.fileName}`)}
      ></img>
    );
  } else {
    return <></>;
  }
}

class SkillSection extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div>
        {skills.data.map((skill, index) => {
          return (
            <div key={index} className="skills-main-div">
              <div className="skills-text-div">
                <Fade right duration={1000}>
                  <h1 className="skills-heading" style={{ color: theme.text }}>
                    {skill.title}
                  </h1>
                </Fade>
                <Fade left duration={2000}>
                  <div className="skills-image-div text-center ">
                    <GetSkillSvg
                      fileName={skill.fileName}
                      alt={skill.title}
                      theme={theme}
                    />
                  </div>
                </Fade>
                <Fade right duration={1500}>
                  <SoftwareSkill logos={skill.softwareSkills} />
                </Fade>
                <Fade right duration={2000}>
                  <div
                    className="subTitle skills-text"
                    style={{ color: theme.secondaryText }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: skill.skills }}
                    ></div>
                  </div>
                </Fade>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default SkillSection;
