import React from "react";
import "./Greeting.css";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import FeelingProud from "./FeelingProud";

export default function Greeting(props) {
  const theme = props.theme;
  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="page-content" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className="title">{greeting.title}</h1>
              <p
                className="greeting-text-p subTitle"
                style={{ color: theme.secondaryText }}
              >
                More than 20 years experience in software development &
                analysis.
                <br />
                <br />
                Operating as freelancer since 2017. <br />
                <br />
                <strong>
                  Passionate about transforming data into information.
                </strong>
                <br />
                <br />
                Heavy focus on:
                <ul>
                  <li>data (modelling)</li>
                  <li>database tuning</li>
                  <li>reporting</li>
                  <li>full stack websites</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="greeting-image-div">
            {/* <img
							alt="saad sitting on table"
							src={require("../../assests/images/feelingProud.svg")}
						></img> */}
            <FeelingProud theme={theme} />
          </div>
        </div>
      </div>
    </Fade>
  );
}
